






































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeRegionDialogProperties } from "./ShippingFeeRegionDialogView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CityService from "@/services/CityService";

@Component({
    data: () => ({
        filter: {
            country: "",
            state: "",
            code: "",
            name: ""
        },
        pageNo: 1,
        pageCount: 1,
        recordCount: 20,
        recordCounts: [10, 20, 30, 40, 50],
        loading: false,
        cities: []
    })
})
export default class ShippingFeeRegionCityView extends Vue {
    @Prop() private properties: ShippingFeeRegionDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private cityService = new CityService();

    public get headers() {
        return [
            {
                text: this.$t("text.country"),
                value: "state.country.name",
                filter: v => {
                    if (!this.$data.filter.country) return true;
                    return v.toLowerCase().includes(this.$data.filter.country.toLowerCase());
                }
            },
            {
                text: this.$t("text.state"),
                value: "state.name",
                filter: v => {
                    if (!this.$data.filter.state) return true;
                    return v.toLowerCase().includes(this.$data.filter.state.toLowerCase());
                }
            },
            {
                text: this.$t("text.code"),
                value: "code",
                filter: v => {
                    if (!this.$data.filter.code) return true;
                    return v.toLowerCase().includes(this.$data.filter.code.toLowerCase());
                }
            },
            {
                text: this.$t("text.name"),
                value: "name",
                filter: v => {
                    if (!this.$data.filter.name) return true;
                    return v.toLowerCase().includes(this.$data.filter.name.toLowerCase());
                }
            }
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.recordCounts
        };
    }

    public created() {        
        this.load();

        this.properties.events.subscribe('load-cities', this.load);
    }

    public destroyed() {
        this.properties.events.remove('load-cities', this.load);
    }

    public async load() {
        this.$data.loading = true;

        try {
            const states = this.properties.selectedStates;
            if (states.length !== 0) {
                var stateId = states.map(x => x.id).join(",");
                const r = await this.cityService.multiple({ stateId, loadState: true, loadCountry: true });
                this.$data.cities = r.data.cities.sort((lhs, rhs) => {
                    var i = lhs.state.country.id - rhs.state.country.id;
                    if (i === 0) {
                        i = lhs.state.id - rhs.state.id;
                        if (i === 0) {
                            return lhs.code.localeCompare(rhs.code);
                        }
                    }
                    return i;
                });
            } else {
                this.$data.cities = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }
}
